<template>
  <div class="telegramads_page">
    <div class="block_1">
      <b-row class="align-items-center">
        <b-col
          cols="12"
          lg="6"
        >
          <h1 class="h1">
            Офіційна реклама в
            <br>
            <b-badge variant="warning">
              Telegram Ads
            </b-badge>
          </h1>
          <h3>Запустіть таргетовану рекламу Telegram Ads та отримайте підписників та клієнтів з Telegram.</h3>
          <div class="btn_c">
            <b-button
              variant="primary"
              size="lg"
              href="https://t.me/TeLeAds_Target"
              target="_blank"
              class="mb-2"
            >
              Отримати консультацію
            </b-button>
          </div>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <img src="/img/photo1710854917.jpeg">
        </b-col>
      </b-row>
    </div>

    <div class="block_2">
      <b-row class="align-items-center">
        <b-col
          cols="12"
          lg="6"
          order-lg="2"
        >
          <h3 class="h3">
            Як працює реклама в Telegram Ads
          </h3>
          <b-card-text class="mb-3">
            Реклама розміщується в нативному форматі під останнім постом у сотнях телеграм каналах, релевантних вашій тематиці. Представлена можливість націлення більш ніж за 40 категоріями інтересів, а також розміщення в будь-яких відкритих каналах на вибір, в яких понад 1000 підписників.
            <span class="badge badge-warning">
              Навіть на канали ваших конкурентів.
            </span>
            Формат реклами: 160 символів + кнопка.
          </b-card-text>
        </b-col>
        <b-col
          cols="12"
          lg="6"
          order-lg="1"
        >
          <img src="/img/telegramads-1.png">
        </b-col>
      </b-row>
    </div>

    <div class="block_3">
      <b-row class="align-items-center">
        <b-col
          cols="12"
          lg="6"
        >
          <h3 class="h3">
            Telegram Ads – зрозумілий та ефективний інструмент реклами, його переваги:
          </h3>
          <ol>
            <li>
              Аудиторія в Telegram постійно зростає, за 2023 рік вона виросла в 2 рази і зростатиме далі. Тут тисячі ваших потенційних клієнтів та передплатників.
            </li>
            <li>
              Унікальний таргетинг. Можливість таргетуватись на певні тематики та канали без обмеженої кількості. І навіть на канали ваших конкурентів!
            </li>
            <li>
              Ефективний формат. Коротке та нативне текстове оголошення з кнопкою переходу. Не дратує та привертає увагу користувачів.
            </li>
            <li>
              Відсутність «тіньового бану» — вашу рекламу побачать усі.
            </li>
            <li>
              Ціна за клієнта та підписника виходить дуже привабливою завдяки мінімальному рівню конкуренції через високий поріг входу та новизні інструменту
            </li>
          </ol>
        </b-col>
        <b-col
          cols="12"
          lg="6"
        >
          <img src="/img/telegramads-2.png">
        </b-col>
      </b-row>
    </div>

    <div class="block_4">
      <b-row>
        <b-col
          cols="12"
        >
          <h3 class="h3">
            Кому підійде Telegram Ads:
          </h3>
          <ol>
            <li>
              Канали, що продають послуги: клініки, юридичні фірми, туризм, дизайн інтер'єру та інші
            </li>
            <li>
              Експертні та авторські канали: бізнеси, що продають консалтинг та навчання: медицина, психологія, заробіток, інвестиції, криптовалюта та інші
            </li>
            <li>
              e-commerce проєкти: меблі та кухні, fashion-бренди, магазини техніки та інші
            </li>
            <li>
              Продуктовий retail
            </li>
            <li>
              Адміністраторам каналів та ботів
            </li>
            <li>
              real property: продаж, оренда, будівництво
            </li>
            <li>
              Великі бренди/компанії, ЗМІ та медіа, освітні проєкти
            </li>
          </ol>
        </b-col>
        <b-col
          cols="12"
          class="d-flex justify-content-center align-items-center"
        >
          <b-button
            variant="warning"
            size="lg"
            href="https://t.me/TeLeAds_Target"
            target="_blank"
            class="mt-1"
          >
            Обговорити запуск реклами для вашого проєкта
          </b-button>
        </b-col>
      </b-row>
    </div>

    <div class="block_5">
      <b-row>
        <b-col
          cols="12"
          class="title"
        >
          <h3 class="h3">
            Новий спосіб генерації лідів та трафіку
          </h3>
          <b-card-text class="mb-3">
            Telegram Ads – рекламне рішення у соціальній мережі Telegram. Генеруйте додатковий трафік до каналу, чат-бота або на сайт, з CPM в 3 рази нижче, ніж в інших каналах.
          </b-card-text>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          md="4"
        >
          <b-card class="shadow-none py-1">
            <h4>Telegram-канал</h4>
            <ul>
              <li>
                швидко набирайте передплатників
              </li>
              <li>
                просуйте окремі пости
              </li>
            </ul>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-card class="shadow-none py-1">
            <h4>Чат-бот</h4>
            <ul>
              <li>
                доводьте більше лідів до контакту
              </li>
              <li>
                допомагайте їм швидше прийняти рішення
              </li>
            </ul>
          </b-card>
        </b-col>
        <b-col
          cols="12"
          md="4"
        >
          <b-card class="shadow-none py-1">
            <h4>Сайт та мобільні додатки</h4>
            <ul>
              <li>
                рекламуйтесь у TG-каналах без посівів
              </li>
              <li>
                генеруйте додатковий трафік на сайт
              </li>
            </ul>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <div class="block_6">
      <b-row>
        <b-col
          cols="12"
        >
          <h3 class="h3">
            Чому нам довіряють просування:
          </h3>
          <app-timeline>
            <app-timeline-item
              v-for="(item, i) in advantages"
              :key="i"
              icon="CheckIcon"
              variant="success"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h5>{{ item }}</h5>
              </div>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </b-row>
      <b-col
        cols="12"
        class="d-flex justify-content-center align-items-center"
      >
        <b-button
          variant="primary"
          size="lg"
          href="https://t.me/TeLeAds_Target"
          target="_blank"
          class="mt-2"
        >
          Замовити просування в Telegram Ads
        </b-button>
      </b-col>
    </div>

    <div class="block_7">
      <b-row>
        <b-col
          cols="12"
          class="title"
        >
          <h3 class="h3">
            Можливі типи таргетингу в Telegram Ads
          </h3>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="(item, index) in targetingTypes"
          :key="index"
          cols="12"
          md="4"
        >
          <b-card class="shadow-none py-1">
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                :icon="item.icon"
                size="24"
              />
            </b-avatar>
            <h4>{{ item.title }}</h4>
            <span class="text-body">{{ item.text }}</span>
          </b-card>
        </b-col>
      </b-row>
    </div>

    <!-- <div class="block_8">
      <h3 class="h3">
        Канали, які ми просували:
      </h3>
      <b-card class="shadow-none py-1">
        <a
          href="https://t.me/KIK_X_files"
          target="_blank"
        >
          <img src="/img/telegramads-3.jpg">
          <span class="text-body">КІК: секретні матеріали</span>
        </a>
      </b-card>
      <b-card class="shadow-none py-1">
        <a
          href="https://t.me/crptmoney"
          target="_blank"
        >
          <img src="/img/telegramads-4.jpg">
          Cards. Money. BTC
        </a>
      </b-card>
      <h3 class="h3">
        Наші результати публікують та показують:
      </h3>
      <b-card class="shadow-none py-1">
        <a
          href="https://ban.media/story/telegram-ads"
          target="_blank"
        >
          <img src="/img/telegramads-5.jpg">
        </a>
      </b-card>
    </div> -->

    <div class="block_11">
      <b-row>
        <b-col
          cols="12"
          class="title"
        >
          <h3 class="h3">
            План співпраці:
          </h3>
        </b-col>
        <b-col
          cols="12"
        >
          <app-timeline>
            <app-timeline-item
              v-for="(item, i) in workingSteps"
              :key="i"
              icon="ClockIcon"
              variant="secondary"
            >
              <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                <h5>{{ item }}</h5>
              </div>
            </app-timeline-item>
          </app-timeline>
        </b-col>
      </b-row>
    </div>

    <div class="block_12">
      <b-row class="text-center">
        <b-col
          cols="12"
          class="title"
        >
          <h3 class="h3">
            Потрібне відкриття кабінету або ведення рекламної кампанії в Telegram Ads, чи консультація?
          </h3>
          <h4 class="mb-3">
            Звертайтесь до нас. Ми зробимо усе на найвищому рівні:
          </h4>
        </b-col>
        <b-col md="4">
          <b-card class="shadow-none py-1">
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="SendIcon"
                size="18"
              />
            </b-avatar>
            <a
              href="https://t.me/TeLeAds_Target"
              target="_blank"
            >
              @TeLeAds_Target
            </a>
            <span class="text-body">Менеджер — співпраця</span>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card class="shadow-none py-1">
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="MailIcon"
                size="18"
              />
            </b-avatar>
            <a
              href="mailto:support@teleads.com.ua"
              target="_blank"
            >
              support@teleads.com.ua
            </a>
            <span class="text-body">Написати на пошту</span>
          </b-card>
        </b-col>
        <b-col md="4">
          <b-card class="shadow-none py-1">
            <b-avatar
              size="42"
              rounded
              variant="light-primary"
              class="mb-2"
            >
              <feather-icon
                icon="SendIcon"
                size="18"
              />
            </b-avatar>
            <a
              href="https://t.me/TeLeDive_UA"
              target="_blank"
            >
              @TeLeDive_UA
            </a>
            <span class="text-body">Канал про Telegram Ads</span>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import GlobalMixin from '@mixins/GlobalMixin'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

export default {
  components: {
    AppTimeline,
    AppTimelineItem,
  },
  mixins: [GlobalMixin],
  metaInfo: {
    title: 'Реклама в Telegram Ads | TeLeAds — біржа реклами в Телеграм',
    meta: [
      { vmid: 'description', name: 'description', content: 'Офіційна реклама в Telegram Ads. Відкриття рекламних кабінетів та ведення рекламних кампаній для бізнесу та телеграм-каналів в Telegram Ads. ✈️ TeLeAds' },
    ],
  },
  data() {
    return {
      advantages: [
        'Не просто тиснемо кнопки в кабінеті, а стаємо вашими партнерами з маркетингу.',
        'Одні з перших запустили Telegram Ads в Україні. А у telegram працюємо 5 років.',
        'Розвинули десятки каналів у Telegram: ЗМІ, експерти, бренди. Канали, які ви читаєте – наші клієнти.',
        'Пріоритетна підтримка у реселерів. Як у великих партнерів за нами закріплені персональні менеджери, які дозволяють нам оперативно вирішувати питання, що виникають по ходу роботи.',
      ],
      targetingTypes: [
        {
          icon: 'UsersIcon',
          title: '41 різні тематичні напрямки',
          text: 'Спорт, харчування, краса і здоров’я, бізнес, новини, інші тематики таргетингів.',
        },
        {
          icon: 'TargetIcon',
          title: 'Спрямування на конкретний канал',
          text: 'Рекламодавець може обрати таргетинг на певний канал чи групу каналів, відібраних вручну.',
        },
        {
          icon: 'GlobeIcon',
          title: 'Мова каналів',
          text: 'Таргетування можливе за мовою каналів. Кількість мов може бути необмеженою.',
        },
        {
          icon: 'PhoneIncomingIcon',
          title: 'За номером телефону користувачів',
          text: 'Будь-який номер телефону з кодом +380.',
        },
        {
          icon: 'MapIcon',
          title: 'Спрямування за регіонами України',
          text: 'На основі IP-адреси користувача. Україна, області, чи окремі міста.',
        },
        {
          icon: 'MonitorIcon',
          title: 'Таргетинг за типом пристрою користувача',
          text: 'IOS, Android або Desktop.',
        },
      ],
      workingSteps: [
        'Залишаєте заявку з інформацією про ваш бізнес/проєкт/канал, який ви хочете рекламувати',
        'Консультація щодо можливості розміщення та стратегії кампанії. Розберемо чи підійде реклама для вашого проєкту і на які результати чекати',
        'Аудит каналу/бота/сайту: допомагаємо підготувати вам канал, бот чи посадкову сторінку сайту для успішної модерації у Telegram Ads',
        'Первинна премодерація каналу у Telegram',
        'Укладання договору та проведення оплат',
        'Розробка стратегії просування та перших рекламних креативів',
        'Тести креативів та гіпотез, досягнення потрібних результатів та вивід кампанії на стабільні показники',
        'Підтримка гарних рекламних показників, постійний аналіз статистики та масштабування',
        'Надаємо детальний звіт по результатам кампанії по даним статистики: охоплень, кліків, підписок, ціни розміщення',
      ],
    }
  },
}
</script>

<style lang="scss">
.telegramads_page {
  .block_1 {
    h1 {
      margin:0 0 1em;
      font-size: 3em;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    h3 {
      margin: 0 0 1em;
    }

    @media (max-width: 700px) {
      h1 {
        font-size: 2em;
      }
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: 4px;
    }
  }

  .block_2 {
    margin-block: 4rem;

    h3 {
      margin-bottom: 1em;
      font-size: 2.4em;
      display: block;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 1.8em;
      }
    }

    .card-text {
      font-size: 1.28em;
      font-weight: 500;
      line-height: 1.5;
      color: #5e5873;
    }

    @media (max-width: 700px) {
      .card-text {
        font-size: 1em;
      }
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: 4px;
    }
  }

  .block_3 {
    margin-block: 4rem;

    h3 {
      margin-bottom: 1em;
      font-size: 2.4em;
      display: block;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 1.8em;
      }
    }

    ol {
      font-size: 1.07rem;
    }

    li {
      margin-bottom: 0.5rem;
    }

    img {
      max-width: 100%;
      height: auto;
      border-radius: 4px;
    }
  }

  .block_4 {
    margin-top: 3em;
    padding: 3em;
    background: #fff;

    @media (max-width: 700px) {
      margin-top: 2em;
      padding: 1em;
    }

    h3 {
      font-size: 2.4em;
      text-align: center;
      display: block;
      margin: 0 0 1em;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 1.8em;
      }
    }

    ol {
      font-size: 1.07rem;
    }

    li {
      margin-bottom: 0.5rem;
    }
  }

  .block_5 {
    padding-bottom: 3em;
    text-align: center;

    @media (max-width: 700px) {
      padding-bottom: 2em;
    }

    h3 {
      max-width: 640px;
      font-size: 2.4em;
      text-align: center;
      display: block;
      margin: 2em auto 0.5em;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 1.8em;
      }
    }

    ul {
      margin-bottom: 0;
      text-align: left;
    }

    .card-text {
      max-width: 880px;
      margin-inline: auto;
      font-size: 1.28em;
      font-weight: 500;
      line-height: 1.5;
      color: #5e5873;
    }

    @media (max-width: 700px) {
      .card-text {
        font-size: 1em;
      }
    }

    .card {
      height: 100%;
      margin-bottom: 0;

      .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    @media (max-width: 700px) {
      .card {
        height: auto;
        margin-bottom: 2rem;
      }
    }
  }

  .block_6 {
    margin-top: 3em;
    padding: 3em;
    background: #fff;

    @media (max-width: 700px) {
      margin-top: 2em;
      padding: 1em;
    }

    h3 {
      font-size: 2.4em;
      text-align: center;
      display: block;
      margin: 0 0 1em;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 1.8em;
      }
    }
  }

  .block_7 {
    padding-bottom: 3em;
    text-align: center;

    @media (max-width: 700px) {
      padding-bottom: 2em;
    }

    .col-12 {
      margin-bottom: 2rem;
    }

    @media (max-width: 700px) {
      .col-12 {
        margin-bottom: 0;
      }
    }

    h3 {
      max-width: 640px;
      font-size: 2.4em;
      text-align: center;
      display: block;
      margin: 2em auto 0.5em;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 1.8em;
      }
    }

    .card-text {
      margin-inline: auto;
      font-size: 1.28em;
      font-weight: 500;
      line-height: 1.5;
      color: #5e5873;
    }

    @media (max-width: 700px) {
      .card-text {
        font-size: 1em;
      }
    }

    .card {
      height: 100%;
      margin-bottom: 0;

      .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    @media (max-width: 700px) {
      .card {
        height: auto;
        margin-bottom: 2rem;
      }
    }
  }

  .block_11 {
    padding: 3em;
    background: #fff;

    @media (max-width: 700px) {
      padding: 1em;
    }

    h3 {
      font-size: 2.4em;
      text-align: center;
      display: block;
      margin: 0 0 1em;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 1.8em;
      }
    }
  }

  .block_12 {
    padding-bottom: 3em;

    h3 {
      max-width: 960px;
      font-size: 2.4em;
      text-align: center;
      display: block;
      margin: 1em auto;
    }

    @media (max-width: 700px) {
      h3 {
        font-size: 1.5em;
      }
    }

    h4 {
      font-size: 1.5rem;
      line-height: 1.5;
    }

    @media (max-width: 700px) {
      h4 {
        font-size: 1rem;
      }
    }

    .card {
      height: 100%;
      margin-bottom: 0;

      .card-body {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      a {
        margin-bottom: 0.5rem;
        font-weight: 500;
        font-size: 1.286rem;
        line-height: 1.2;
        color: #5e5873;
        word-break: break-all;
      }
    }

    @media (max-width: 700px) {
      .card {
        height: auto;
        margin-bottom: 2rem;
      }
    }
  }
}

@import "~@core/scss/base/pages/app-ecommerce.scss";
//@import "https://telega.in/assets/home/application-0aa30cd3fcd937f1f1f57949a12b769010c73736d5e4584cb9ac1cc240f4d635.css";
</style>
